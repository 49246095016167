

$(function(){
	$('.menu__btn,.menu__list').click(function(event){
		$('.menu__btn,.menu__list').toggleClass('active');
	})
	
	$('.case__items').slick({
		centerMode: true,
		centerPadding: '0px',
  slidesToShow: 3,
		arrows: false,
		dots: true,
		autoplay: true,
		autoplaySpeed: 6000,
		responsive: [
			{
					breakpoint: 1000,
					settings: {
							centerPadding: '0px',
							slidesToShow: 2,
					},
			},
			{
				breakpoint: 700,
					settings: {
							slidesToShow: 1,
					}
			}
	]
	});


	
});

jQuery(document).ready(function () {

	$(".phone").mask("+7 (999) 999-99-99"); 

jQuery('.contact__btn').click( function() {
	var form = jQuery(this).closest('form');
	
	if ( form.valid() ) {
		form.css('opacity','.5');
		var actUrl = form.attr('action');

		jQuery.ajax({
			url: actUrl,
			type: 'post',
			dataType: 'html',
			data: form.serialize(),
			success: function(data) {
				form.html(data);
				form.css('opacity','1');
																//form.find('.status').html('форма отправлена успешно');
																//$('#myModal').modal('show') // для бутстрапа
			},
			error:	 function() {
								form.find('.status').html('серверная ошибка');
			}
		});
	}
});
});

function readMore() {
	var dots = document.getElementById("news__item-dots");
	var more = document.getElementById("news__item-more");
	var btn = document.getElementById("news__item-btn");
	
	if (dots.style.display === "none"){
		dots.style.display = "inline";
		btn.innerHTML = "Подробнее";
		more.style.display = "none";
	} else {
		dots.style.display = "none";
		btn.innerHTML = "Скрыть";
		more.style.display = "inline";
	}
}